import React from 'react'
import { Link } from 'gatsby'
import Html from './html'
import CollectionCards from './collection-cards'

export default function Playsets ({ title, description, buyButtonLabel, collection }) {
  return (
    <section className='u-sectionDivider u-noPrint'>
      <div className='u-sectionPadded'>
        <h2 className='u-centered u-max-md'>
          {title}
        </h2>
        <Html className='Page-content u-centered u-max-sm u-marginBottom'>
          {description}
        </Html>
      </div>
      <div className='u-centered'>
        <CollectionCards
          collection={collection}
        />
        {buyButtonLabel && (
          <section className='u-marginTop'>
            <Link className='Button Button--shop' to='/shop/'>
              {buyButtonLabel}
            </Link>
          </section>
        )}
      </div>
    </section>
  )
}
